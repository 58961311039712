import {
  Badge,
  Drawer,
  Backdrop,
  IconButton,
  styled,
  Button,
  Stack,
} from '@mui/material';
import xw from 'xwind';

import { Container } from '@/components/layout';
import { RawUnauthItems } from './RawUnauthItems';

export const Header = styled('header')<{ top?: number }>`
  position: sticky;
  top: ${({ top }) => top}px;
  background-color: ${({ theme }) => theme.palette.common.white};
  z-index: 50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }) => theme.spacing(5.75)};
    padding-bottom: ${({ theme }) => theme.spacing(5.75)};
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    & > * + * {
      margin-left: ${({ theme }) => theme.spacing(4)};
    }
  }
  position: relative;
`;

export const LogoWrapper = styled('div')<{ collapsed?: boolean }>`
  width: ${({ theme }) => theme.spacing(52)};
  display: flex;
  align-items: center;
  opacity: ${({ collapsed = false }) => (collapsed ? 0 : 1)};
  transition: width 0.2s ease-in-out, height 0.2s 0.1s ease-out,
    opacity 0.5s linear;
  width: ${({ theme }) => theme.spacing(42)};
  height: ${({ theme }) => theme.spacing(8)};
  align-self: center;
  margin-right: ${({ theme }) => theme.spacing(0)};
  max-width: calc(100% - 170px);

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: none;
    flex: 0 1 auto;
    opacity: 1;
    width: ${({ theme }) => theme.spacing(48)};
    height: ${({ theme }) => theme.spacing(10)};
  }
`;

export const Counter = styled(Badge)`
  .MuiBadge-badge {
    min-width: 18px;
    height: 18px;
    padding: 0 3px;
    top: ${({ theme }) => theme.spacing(-4)};
    left: ${({ theme }) => theme.spacing(-4)};
    bottom: auto;
    right: auto;
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 700;
  }
`;

export const PageBackdrop = styled(Backdrop)`
  margin: 0;
  z-index: 2147483500;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const SearchWrapper = styled('div')`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  margin-right: auto;
  height: 100%;
  padding: 0 ${({ theme }) => theme.spacing(4)};
  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-left: 0;
  }

  position: absolute;
  left: -2px;
  right: -2px;
  z-index: 2147484000;
  .MuiAutocomplete-root {
    display: flex;
  }
  > .MuiButton-root {
    display: none;
  }

  &.hidden {
    display: none;
  }

  > .MuiButton-root {
    display: none;
  }
`;

export const MobileSearchWrapper = styled('div')<{ open: boolean }>`
  display: flex;
  align-items: center;
  transition: width 0.2s;
  margin-left: auto;
  margin-right: ${({ theme }) => theme.spacing(3)};
  &.focused {
    position: absolute;
    left: -2px;
    right: -2px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
    width: 0;
    height: 0;
  }
`;

export const IconMenuButton = styled(Button)<{
  component?: unknown;
  mobileTabbar?: boolean;
}>`
  font-size: ${({ theme }) => theme.spacing(5)} !important;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  padding: ${({ theme }) => theme.spacing(3)};

  ${({ theme, mobileTabbar }) =>
    mobileTabbar
      ? `
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${theme.spacing(13.5)};
    min-width: ${theme.spacing(13.5)};
    border-radius: ${theme.spacing(3)};
  `
      : ''}
`;

export const DropdownWrapper = styled('div')<{
  authorized: number;
}>`
  flex: 1 1 100%;
  transition: width 0.2s ease-in-out, height 0.2s 0.1s ease-out,
    opacity 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0;
  margin-top: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(3)};
  & > * {
    flex: 1 1 50%;
  }
  max-width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: 0;
    gap: ${({ theme, authorized }) => theme.spacing(authorized ? 3 : 7.5)};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex: 0 1 auto;
    height: 100%;
    opacity: 1;
    margin-top: 0;
    margin-left: ${({ theme, authorized }) =>
      theme.spacing(authorized ? 1 : 7.5)};
    & > * {
      flex: 1 1 auto;
    }
  }
`;

export const DropdownWrapperSimple = styled(Stack)``;

export const AuthorizedDropdownWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 100%;
`;

export const IconBtn = styled(IconButton)`
  height: fit-content;
  &:hover {
    color: ${({ theme }) => theme.palette.primary[500]};
  }
`;

export const UnauthItems = styled(RawUnauthItems)`
  display: none;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: block;
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
  ${({ theme }) => theme.breakpoints.down('xl')} {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
  }
`;

export const NavbarDrawer = styled(Drawer)`
  nav .MuiButton-root {
    min-width: ${({ theme }) => theme.spacing(93)};
  }
`;

export const JoinButton = styled(Button)(xw`px-2 sm:px-9`);
